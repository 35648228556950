.inputContainer {
  text-align: left;
  margin-bottom: 20px !important;
}

.inputLabel {
  color: #646777;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}

.previewImage {
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.requiredMessage {
  color: #ababab;
}