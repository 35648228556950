.inputContainer {
  padding-bottom: 40px;
}

.imageSection {
  padding-bottom: 20px;
}

.imageUploadButtonContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.linkSection {
  padding-bottom: 20px;
}

.postSearchField {
  width: 200px;
}
