.inputContainer {
  text-align: left;
  margin-bottom: 20px !important;
}

.inputContainerNoPadding {
  text-align: left;
}

.inputLabel {
  color: #646777;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}