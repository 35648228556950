.login-container {
  text-align: center;
  background-color: #FAFAFA;
  height: 100vh;
}

.login-form-container {
  margin-top: 200px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.login-input-container {
  text-align: left;
  margin-bottom: 12px !important;
}

.login-label {
  padding-bottom: 3px;
}

.login-button {
  width: 100%;
  margin-top: 8px;
}

.login-form-error-message {
  color: #ff0000;
}

