/* ---START--- ARTICLE CONTENT */

.gt-article-details-title {
  font-size: 26px;
  font-weight: bold;
  padding-bottom: 5px;
}

.gt-article-details-content {
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  padding-bottom: 20px;
  width: 100%;
}

.gt-article-details-paragraph {
  padding-top: 40px;
}

.gt-article-details-paragraph-title {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  font-weight: 600;
}

.gt-article-details-paragraph-text {
  white-space: pre-wrap;
}

.gt-article-details-paragraph:first-child {
  padding-top: 0px;
}

.gt-article-details-link {
  color: #2069c7;
}

.gt-article-details-subtitle {
  padding-top: 50px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

.gt-article-details-image-container {
  /* padding-top: 20px; */
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
}

.gt-article-details-image {
  cursor: pointer;
  padding-top: 20px;
  width: 100%;
}

.gt-article-details-image-caption {
  color: #666666;
  font-size: 14px;
  padding-top: 2px;
}

.gt-article-details-video-container {
  padding-top: 20px;
  padding-bottom: 20px;
  /* width: 100%;
  text-align: center; */
}

.gt-article-details-video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.gt-article-details-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gt-article-details-links-container {
  padding-top: 40px;
  padding-bottom: 20px;
}

.gt-article-details-link {
  display: inline-block;
  padding-right: 10px;
}

/* ---END--- ARTICLE CONTENT */

/* ---START--- GAME CONTENT */
.details-game-introduction {
  font-size: 16px;
  line-height: 34px;
  text-align: justify;
  padding-bottom: 20px;
  width: 100%;
}

.details-game-content {
  font-size: 16px;
  line-height: 34px;
  text-align: justify;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.details-game-paragraph {
  padding-top: 32px;
}

.details-game-title {
  font-size: 24px;
  font-weight: bold;
  padding-top: 10px;
}

.details-game-subtitle {
  font-size: 19px;
  font-weight: bold;
}

/* ---END--- GAME CONTENT */

.gt-game-platform {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
  height: 22px;
  width: fit-content;
  border-radius: 11px;
  color: #FFFFFF;
}

.gt-game-platform:last-child {
  margin-right: 0px;
}

.gt-game-platform-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 4px 8px;
}

.gt-game-platform-default {
  background-color: rgb(160, 160, 160);
}

.gt-game-platform-ps {
  /* background-color: rgb(0, 65, 152); */
  background-color: rgb(30, 72, 172);
}

.gt-game-platform-nintendo {
  background-color: rgb(222, 0, 18);
}

.gt-game-platform-xbox {
  background-color: rgb(16, 124, 16);
}

.gt-game-platform-pc {
  background-color: rgb(0, 166, 242);
}

.gt-game-platform-ios {
  background-color: rgb(0, 0, 0);
}

.gt-game-platform-android {
  background-color: rgb(159, 192, 55);
}

.gt-row {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.gt-col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.gt-col-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.gt-col-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.gt-col-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* @media (min-width: 768px) {
  .gt-col-1 {
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .gt-col-2 {
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .gt-col-3 {
    -webkit-flex: 0 0 33.3%;
    -moz-box-flex: 0;
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
  
  .gt-col-4 {
    -webkit-flex: 0 0 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
} */