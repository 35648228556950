.inputFormRowContainer {
  padding-bottom: 10px;
}

.inputFormLeftContainer {
  padding-right: 10px;
}

.inputFormRightContainer {
  padding-left: 10px;
}

.inputFormSubtitle {
  font-weight: bold;
  padding-bottom: 10px;
}

.previewSection {
  border: 1px solid #eeeeee;
}

.previewContentContainer {
  padding-left: 10%;
  padding-right: 10%;
}

.elementContainer {
  width: 100%;
  padding: 10px;
  border: 1px solid #eeeeee;
  margin-top: 10px;
  margin-bottom: 10px;
}

.elementTitle {
  font-weight: bold;
}

.elementSampleText {
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 24px;
}

.elementActionContainer {
  text-align: right;
}

.elementFormImageSection {
  padding-bottom: 20px;
}

.elementFormImageUploadButtonContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.elementInputPadding {
  padding-top: 20px;
}

.editNewsSearchSectionBlock {
  display: inline-block;
  padding-right: 20px;
  width: 200px;
}
